import React, { useEffect } from 'react'
import { clockIcon, locationIcon, phoneScanIcon } from '../../assets/svgs'
import premblyLogo from "../../assets/premblyLogo.svg"
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function QRPage() {
    const navigate = useNavigate();
    let orgName = Cookies.get('company') || ""
    useEffect(() => {
        
    }, [])
    
    let triggerScan = ()=>{
        navigate("/QRResult");
    }
    return (
        <div className='check-in-area'>
            <div className="container">
                <div className="text-center pt-5">
                    <div className="d-flex justify-content-center">
                        <h4 style={{ color: "#316A31" }}>Powered by </h4>
                        <img width="40px" className='ms-2' alt="" src={premblyLogo} />
                        <h4 style={{ color: "#316A31" }}> prembly</h4>
                    </div>
                    <h1 className='mt-3' style={{ color: "#316A31" }}>Checked In as {orgName}</h1>
                </div>
                <div className="col-md-8 mx-auto pt-4">
                    <div className='showcase-area'>
                        <h3> Welcome to  Microfinance Retreat & Investors’ Conference</h3>
                        <div className="row mt-2">
                            <div className="col-md-4 mb-2">
                                <div className="d-flex align-items-center ">
                                    <span className='me-1'>{clockIcon}</span>
                                    <p className='m-0'> 21st - 23rd  September 2023</p>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="d-flex align-items-center">
                                    <span className='me-1'>{locationIcon}</span>
                                    <p className='m-0'> Ogere Resort, Ogun State, Nigeria.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='scan-button mt-4 text-center mx-auto' onClick={triggerScan}>
                        <span>{phoneScanIcon}</span>
                        <h5 className='mt-3'>Scan QR Code</h5>
                    </div>

                </div>
            </div>
        </div>
    )
}

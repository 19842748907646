import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import premblyLogo from "../../assets/premblyLogo.svg"

export default function ExhibitorCheckIn() {
    const [companyName, setCompanyName] = useState("")
    const [allCompanies, setAllCompanies] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
      getAllCompanies()
    
    }, [])
    

    let getAllCompanies = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        }
        fetch('https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/identity-reg-en-dev/event/merch', requestOptions)
        .then((response) => response.json())
        .then((result) => {
            setAllCompanies(result?.company)
        })
        .catch((error) => console.log('error', error))
    }

    // let getAllCompanies = () => {
    //     let dummy = [
    //         {name: "Amani Health"},
    //         {name: "Chronicle Soft"},
    //         {name: "Corona Collage of Education"},
    //         {name: "Minimonie"},
    //         {name: "NPF"},
    //         {name: "SKLD Integrated Services"},
    //     ]
    //     setAllCompanies(dummy)
    // }


    let checkIn = () => {
        if (!companyName) {
            toast.error( 'Company name cannot be empty',)
        }
        else {
            Cookies.set('company', companyName)
            navigate("/ScanQR");
        }
    }

    return (
        <div className='check-in-area'>
            <div className="container">
                <div className="text-center pt-5">
                    <div className="d-flex justify-content-center">
                        <h4 style={{ color: "#316A31" }}>Powered by </h4>
                        <img width="40px" className='ms-2' alt="" src={premblyLogo} />
                        <h4 style={{ color: "#316A31" }}> prembly</h4>
                    </div>
                    <h1 className='mt-3' style={{ color: "#316A31" }}>Merchant/Exhibitor Check-in</h1>
                </div>
                <div className="col-md-6 mx-auto pt-4">
                    <div className="mt-3">
                        <select className='form-select' name="" id="" onChange={e => setCompanyName(e.target.value)}>
                            <option value="">Select your organization</option>
                            {allCompanies?.map((val,i)=>(
                                <option key={i} value={val?.name}>{val?.name}</option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="mt-3">
                        <input type="text" className="form-control" placeholder='Booth Number' onChange={e => setBoothNumber(e.target.value)} />
                    </div> */}
                    <button className='btn btn-green mt-3 px-4' onClick={checkIn}> Check In</button>
                </div>
            </div>
        </div>
    )
}

import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-scanner'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import premblyLogo from "../../assets/premblyLogo.svg"


export default function ScanQrPage() {
    const [result, setResult] = useState("")
    const [loader, setLoader] = useState(false)

    const navigate = useNavigate();

    let company = Cookies.get("company") || ""

    useEffect(() => {
        if(!company){
            navigate("/");
        }
    }, [])
    
    let handleScan = (data) => {
        if(data){
            setResult(JSON?.parse(data?.text))
        }
    }

    let handleError = (err) => {
        console.log(err)
    }

    let triggerDownload = () => {
        if (!result) {
            toast.error(
                'You cannot save an empty response',
            )
        }
        else {
            setLoader(true)

            let body = {
                fullName:result?.fullName,
                email:result?.email,
                mobileNumber:result?.mobileNumber,
                company:result?.company,
                role:result?.role,
                address:result?.address,
                registrationType:result?.registrationType,
                exibithor:company,
            }

            var requestOptions = {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",  
                //   "exibithor":company,
                },
                body: JSON.stringify(body),
                redirect: 'follow',
            }
            fetch('https://o5rvlgg4dk.execute-api.us-east-2.amazonaws.com/dev/identity-reg-en-dev/event/add-lead', requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setLoader(false)
                    if(result?.Success){
                        toast.success( 'Data successfully saved')
                        setTimeout(
                            ()=>{
                                window.location = `https://exhibitors.prembly.com/ScanQR`
                            }, 2000
                        )
                    }
                    else{
                        toast.error( result?.message)
                    }
                })
                .catch((error) => {
                    setLoader(false)
                    toast.error( error?.detail)
                    // console.log('error', error)
                })

        }

    }

    return (
        <div className='check-in-area'>
            <div className="container">
                <div className="text-center pt-5">
                    <div className="d-flex justify-content-center">
                        <h4 style={{ color: "#316A31" }}>Powered by </h4>
                        <img width="40px" className='ms-2' alt="" src={premblyLogo}/>
                        <h4 style={{ color: "#316A31" }}> prembly</h4>
                    </div>
                    <h1 className='mt-3' style={{ color: "#316A31" }}>Checked In as {company}</h1>
                </div>
                <div className="col-md-8 mx-auto pt-4">
                    {!result &&
                        <div className="text-center">
                            <div className="qr-camera-area">
                                <QrReader
                                    delay={100}
                                    style={{ height: 460, width: 260 }}
                                    onError={handleError}
                                    onScan={handleScan}
                                    constraints={{
                                        video: { facingMode: "environment" }
                                    }}
                                />
                            </div>
                            <p>{result}</p>
                        </div>
                    }

                    {result &&
                        <div className="scanned-response">
                            <div className="card col-md-8 mx-auto p-4" style={{backgroundColor:"#F0F5F2", border:"none" }}>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h3 className='mb-4' style={{ color: "#316A31"}}>Personal Information</h3>
                                        {/* <img className="response-img" src=''/> */}
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <span className="col-md-6">
                                            <small>Full Name</small>
                                            <p>{result?.fullName || 'Not Available'}</p>
                                        </span>
                                        <span className="col-md-6 text-md-end">
                                            <small>Email</small>
                                            <p>{result?.email || 'Not Available'}</p>
                                        </span>
                                    </div><hr />
                                    <div className='row justify-content-between mt-3'>
                                        <span className="col-md-6">
                                            <small>Mobile Number</small>
                                            <p>{result?.mobileNumber || 'Not Available'}</p>
                                        </span>
                                        <span className="col-md-6 text-md-end">
                                            <small>Address</small>
                                            <p>{result?.address || 'Not Available'}</p>
                                        </span>
                                    </div><hr />
                                    <div className='row justify-content-between mt-3'>
                                        <span className="col-md-6">
                                            <small>Organization</small>
                                            <p>{result?.company || 'Not Available'}</p>
                                        </span>
                                        <span className="col-md-6 text-md-end">
                                            <small>Role</small>
                                            <p>{result?.role || 'Not Available'}</p>
                                        </span>
                                    </div><hr />
                                    <div className='row justify-content-between mt-3'>
                                        <span className="col-md-6">
                                            <small>Registration Type</small>
                                            <p>{result?.registrationType || 'Not Available'}</p>
                                        </span>
                                    </div><hr />
                                    <button className='btn btn-green mt-4 w-100 py-3' onClick={triggerDownload} disabled={loader}> 
                                        {loader ?
                                            " Loading..."
                                            : "Save details"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


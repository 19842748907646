import { BrowserRouter, Route, Routes } from "react-router-dom";
import ExhibitorCheckIn from "./pages/checkIn";
import QRPage from "./pages/qr";
import ScanQrPage from "./pages/scanQR";

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ExhibitorCheckIn />} />
        <Route path="/ScanQR" element={<QRPage />} />
        <Route path="/QRResult" element={<ScanQrPage />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;

export const phoneScanIcon = (
	<svg width="80" height="144" viewBox="0 0 80 144" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="19" y1="12" x2="61" y2="12" stroke="#316A31" strokeWidth="2" strokeLinecap="round" />
		<g clipPath="url(#clip0_312_657)">
			<path d="M26 67H36.5V77.5H26V67ZM54 67V77.5H43.5V67H54ZM43.5 86.25H47V82.75H43.5V79.25H47V82.75H50.5V79.25H54V82.75H50.5V86.25H54V91.5H50.5V95H47V91.5H41.75V95H38.25V88H43.5V86.25ZM47 86.25V91.5H50.5V86.25H47ZM26 95V84.5H36.5V95H26ZM29.5 70.5V74H33V70.5H29.5ZM47 70.5V74H50.5V70.5H47ZM29.5 88V91.5H33V88H29.5ZM26 79.25H29.5V82.75H26V79.25ZM34.75 79.25H41.75V86.25H38.25V82.75H34.75V79.25ZM38.25 70.5H41.75V77.5H38.25V70.5ZM22.5 63.5V70.5H19V63.5C19 62.5717 19.3687 61.6815 20.0251 61.0251C20.6815 60.3687 21.5717 60 22.5 60H29.5V63.5H22.5ZM57.5 60C58.4283 60 59.3185 60.3687 59.9749 61.0251C60.6312 61.6815 61 62.5717 61 63.5V70.5H57.5V63.5H50.5V60H57.5ZM22.5 91.5V98.5H29.5V102H22.5C21.5717 102 20.6815 101.631 20.0251 100.975C19.3687 100.318 19 99.4283 19 98.5V91.5H22.5ZM57.5 98.5V91.5H61V98.5C61 99.4283 60.6312 100.318 59.9749 100.975C59.3185 101.631 58.4283 102 57.5 102H50.5V98.5H57.5Z" fill="#316A31" />
		</g>
		<rect x="1" y="1" width="78" height="142" rx="9" stroke="#316A31" strokeWidth="2" />
		<defs>
			<clipPath id="clip0_312_657">
				<rect width="42" height="42" fill="white" transform="translate(19 60)" />
			</clipPath>
		</defs>
	</svg>
)

export const clockIcon = (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="white" />
		<path d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" fill="white" />
	</svg>
)

export const locationIcon = (
	<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8 10C8.55 10 9.021 9.804 9.413 9.412C9.80433 9.02067 10 8.55 10 8C10 7.45 9.80433 6.979 9.413 6.587C9.021 6.19567 8.55 6 8 6C7.45 6 6.97933 6.19567 6.588 6.587C6.196 6.979 6 7.45 6 8C6 8.55 6.196 9.02067 6.588 9.412C6.97933 9.804 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7873 12.525 12.262C13.5083 10.7373 14 9.38333 14 8.2C14 6.38333 13.4207 4.89567 12.262 3.737C11.104 2.579 9.68333 2 8 2C6.31667 2 4.89567 2.579 3.737 3.737C2.579 4.89567 2 6.38333 2 8.2C2 9.38333 2.49167 10.7373 3.475 12.262C4.45833 13.7873 5.96667 15.4833 8 17.35ZM8 19.625C7.86667 19.625 7.73333 19.6 7.6 19.55C7.46667 19.5 7.35 19.4333 7.25 19.35C4.81667 17.2 3 15.2043 1.8 13.363C0.6 11.521 0 9.8 0 8.2C0 5.7 0.804333 3.70833 2.413 2.225C4.021 0.741667 5.88333 0 8 0C10.1167 0 11.979 0.741667 13.587 2.225C15.1957 3.70833 16 5.7 16 8.2C16 9.8 15.4 11.521 14.2 13.363C13 15.2043 11.1833 17.2 8.75 19.35C8.65 19.4333 8.53333 19.5 8.4 19.55C8.26667 19.6 8.13333 19.625 8 19.625Z" fill="white" />
	</svg>
)